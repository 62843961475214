body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* animation start */
  animation: colorchange 200s linear 1s infinite; /* animation-name followed by duration in seconds*/
       /* you could also use milliseconds (ms) or something like 2.5s */
  -webkit-animation: colorchange 200s linear 0s infinite alternate; /* Chrome and Safari */
  /* animation end */
}

@keyframes colorchange
{
  0%   {background: red; color: cyan;}
  33%  {background: green; color: magenta;}
  66%  {background: blue; color: yellow;}
  100% {background: red; color: cyan;}
}

@-webkit-keyframes colorchange /* Safari and Chrome - necessary duplicate */
{
  0%   {background: red; color: cyan;}
  33%  {background: green; color: magenta;}
  66%  {background: blue; color: yellow;}
  100% {background: red; color: cyan;}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
